var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
        [
          _c(
            "c-table",
            {
              ref: "table",
              attrs: {
                title: "유소견자 등록 대상자 목록",
                tableId: "table",
                columns: _vm.grid.columns,
                data: _vm.grid.data,
                checkClickFlag: true,
                columnSetting: false,
                selection: "multiple",
                selected: _vm.grid.selected,
                isExcelDown: false,
                rowKey: "heaCheckupResultOpinionId",
                gridHeightAuto: "",
              },
            },
            [
              _c(
                "template",
                { slot: "table-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _vm.editable && _vm.grid.data.length > 0
                        ? _c("c-btn", {
                            attrs: {
                              url: _vm.saveUrl,
                              isSubmit: _vm.isSave,
                              param: _vm.selectData,
                              mappingType: "POST",
                              label: "유소견자 등록",
                              icon: "save",
                            },
                            on: {
                              beforeAction: _vm.saveRow,
                              btnCallback: _vm.saveCallback,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }