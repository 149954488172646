<template>
  <div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="table"
        title="유소견자 등록 대상자 목록"
        tableId="table"
        :columns="grid.columns"
        :data="grid.data"
        :checkClickFlag="true"
        :columnSetting="false"
        selection="multiple"
        :selected="grid.selected"
        :isExcelDown="false"
        rowKey="heaCheckupResultOpinionId"
        gridHeightAuto
      >
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn v-if="editable && grid.data.length>0"
              :url="saveUrl"
              :isSubmit="isSave"
              :param="selectData"
              mappingType="POST"
              label="유소견자 등록"
              icon="save"
              @beforeAction="saveRow"
              @btnCallback="saveCallback" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'dept-harmful-factors-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        heaCheckupPlanId: '',
      }),
    },
  },
  data() {
    return {
      mappingType: 'POST',
      saveUrl: '',
      deleteUrl: '',
      revlistUrl: '',
      chemName: '',
      checkUrl: '',
      subListUrl: '',
      subSaveUrl: '',
      subDeleteUrl: '',
      editable: true,
      isSave: false,
      isSave2: false,
      isdelete: false,
      isRevision: false,
      updateMode: false,
      checkUp: {
        heaCheckupPlanId: '',
        plantCd: '',
        checkupName: '',
        checkupTypeCd: null,
        regUserId: '',
        chgUserId: '',
        hospitalId: null,
        hospitalName: '',
        checkupResults: [], //검진결과
        checkupOpinions: [], // 검진소견
      },
      period: [],
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
      grid: {
        merge: [
          { index: 0, colName: 'mergeGroup' },
        ],
        columns: [
          {
            name: 'deptName',
            field: 'deptName',
            label: '소속',
            align: 'center',
            style: 'width: 100px',
            sortable: false,
          },
          {
            name: 'empNo',
            field: 'empNo',
            label: '사번',
            align: 'center',
            style: 'width: 100px',
            sortable: false,
          },
          {
            name: 'userName',
            field: 'userName',
            label: '대상자',
            align: 'center',
            style: 'width: 100px',
            sortable: false,
          },
          {
            name: 'birthDate',
            field: 'birthDate',
            label: '생년월일',
            align: 'center',
            style: 'width: 100px',
            sortable: false,
          },
          {
            name: 'checkupDate',
            field: 'checkupDate',
            label: '검진일',
            align: 'center',
            style: 'width: 100px',
            sortable: false,
          },
          {
            name: 'healthType',
            field: 'healthType',
            label: '건강구분',
            align: 'center',
            style: 'width:70px',
            sortable: false,
          },
          {
            name: 'suitableFlag',
            field: 'suitableFlag',
            label: '업무수행 적합여부',
            align: 'center',
            style: 'width:70px',
            sortable: false,
          },
          {
            name: 'hazardName',
            field: 'hazardName',
            label: '유해인자',
            align: 'center',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'checkupOpinion',
            field: 'checkupOpinion',
            label: '검진소견',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'opinionDetailResult',
            field: 'opinionDetailResult',
            label: '소견 세부결과',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'afterManage',
            field: 'afterManage',
            label: '사후관리소견',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'remark',
            field: 'remark',
            label: '비고',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
        ],
        data: [],
      },
      selectData: [],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.hea.checkup.suspect.user.target.url;
      this.saveUrl = transactionConfig.hea.checkup.suspect.user.save.url;
      this.getList();
    },
    getList() {
      if (this.popupParam.heaCheckupPlanId) {
        this.$http.url = this.listUrl
        this.$http.type = 'GET';
        this.$http.param = {
          heaCheckupPlanId: this.popupParam.heaCheckupPlanId,
          healthTypes: ['C1', 'C2', 'CN', 'D1', 'D2', 'DN', 'R']
        }
        this.$http.request((_result) => {
          this.grid.data = _result.data;
          // 모든 데이터를 선택된 상태로 설정
          this.grid.selected = [..._result.data];
        },);
      } 
    },
    saveRow() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '유소견자에 등록하시겠습니까?'+' ['+selectData.length+' 건]',
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$_.forEach(selectData, _item => {
              this.selectData.push({
                suspectId: uid(),
                heaCheckupResultOpinionId: _item.heaCheckupResultOpinionId,
                heaCheckupPlanId: _item.heaCheckupPlanId,
                userId: _item.userId,
                userName: _item.userName,
                healthType: _item.healthType,
                regUserId:  this.$store.getters.user.userId,
              })
            })
            this.isSave = !this.isSave;
            this.$refs['table'].selected = []
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$emit('closePopup')
    },
  }
};
</script>
